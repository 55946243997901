'use client';

import React from 'react';
import dynamic from 'next/dynamic';
import { useMobileCropperStore } from '@/store/useMobileCropperStore';
import MiniLoader from '../UI/MiniLoader/MiniLoader';

import '../MobileCropper/mobileCropper.scss';

const MobileCropperLazy = dynamic(() => import('../MobileCropper/MobileCropper'), {
    loading: () => <MiniLoader />,
});

const _MobileCropperProvider = ({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) => {
    const { isOpen } = useMobileCropperStore();
    return (
        <>
            {isOpen && (
                <div className={'mini-cropper'}>
                    <MobileCropperLazy />
                </div>
            )}
            {children}
        </>
    );
};

export default _MobileCropperProvider;
