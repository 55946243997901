'use client';

import Router from 'next/router';
import { useCallback, useEffect, FC } from 'react';
import { YMInitializer } from 'react-yandex-metrika';

interface IYMetrics {
    enabled: boolean;
}

const YMetrics: FC<IYMetrics> = ({ enabled }) => {
    const hit = useCallback(
        async (url: string) => {
            if (enabled) {
                const ym = await import('react-yandex-metrika');
                ym.default('hit', url);
            } else {
                console.log(`%c[YandexMetrika](HIT)`, `color: orange`, url);
            }
        },
        [enabled]
    );

    useEffect(() => {
        hit(window.location.pathname + window.location.search);
        Router.events.on('routeChangeComplete', (url: string) => hit(url));
    }, [hit]);

    if (!enabled) return null;

    return (
        <YMInitializer
            accounts={[98789423]}
            options={{
                defer: true,
                webvisor: true,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
            }}
            version="2"
        />
    );
};

export default YMetrics;
