'use client';

import React from 'react';
import { Toaster } from 'react-hot-toast';

const ToastNotify = () => {
    return (
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
                className: 'base-toast',
                duration: 4000,
                success: {
                    duration: 2000,
                },
            }}
        />
    );
};

export default ToastNotify;
