import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ICurrentContestStore } from '@/lib/types/store/CurrentContestStore.type';
import { IParticipant } from '@/lib/types/services/Competiton.type';

export const useCurrentContestStore = create<ICurrentContestStore>()(
    devtools((set, get) => ({
        currentContest: null,
        userLikeId: -1,
        availableStars: null,
        setAvailableStars(stars_list) {
            set({ availableStars: stars_list });
        },
        setUserLikeId: (userID) => {
            set({ userLikeId: userID });
        },
        setCurrentContest: (contest) => {
            const likeUserId = contest?.participants_list.find((x) => x.like_status == true);

            set({
                currentContest: contest,
                userLikeId: likeUserId?.user_id || -1,
            });
        },
        updateParticipantsList: (payload: IParticipant[]) => {
            let currentContest = get().currentContest;

            let newList = currentContest?.participants_list.map((x) => payload.find((o) => o.id === x.id) || x);

            if (newList && currentContest) {
                set({
                    currentContest: {
                        ...currentContest,
                        participants_list: newList,
                    },
                });
            }
        },
        boostParticipantsList: (payload: IParticipant[]) => {
            let currentContest = get().currentContest;

            if (currentContest) {
                set({
                    currentContest: {
                        ...currentContest,
                        participants_list: [...payload],
                    },
                });
            }
        },
    }))
);
