'use client';

import React from 'react';

import { useSocketSubscription } from '@/hooks/useSocketSubscription';
import { useAppStore } from '@/store/useAppStore';

const MainWrapper = ({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) => {
    const { getUserIP } = useAppStore();

    React.useEffect(() => {
        getUserIP();
    }, []);

    useSocketSubscription();

    return <>{children}</>;
};

export default MainWrapper;
