import(/* webpackMode: "eager" */ "/app/src/app/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Metrics/YMetrics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Providers/_MobileCropperProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Providers/_QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Providers/MainWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/UI/ToastNotify/ToastNotify.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["_GalleryContextProvider"] */ "/app/src/context/useGalleryContest.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["_ModalContextProvider"] */ "/app/src/context/useModalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["_TelegramProvider"] */ "/app/src/context/useTelegramContext.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/scss/globals.scss");
;
import(/* webpackMode: "eager" */ "/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/node_modules/next/font/local/target.css?{\"path\":\"src/lib/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/Gilroy-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Gilroy-Thin.woff2\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Gilroy-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Gilroy-Semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Gilroy-Extrabold.woff2\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Gilroy-Black.woff2\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Gilroy-Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Gilroy-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"normal\"}]}],\"variableName\":\"fonts\"}");
;
import(/* webpackMode: "eager" */ "/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/node_modules/react-loading-skeleton/dist/skeleton.css");
